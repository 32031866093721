import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import LineTagComponent from "../tags/LineTagComponent"

interface Props {
  title: string
}

export default function ArticleSidebar({ title }: Props) {
  return (
    <div className="col-span-12 md:col-span-4 pr-6">
      <div className="ml-0 md:ml-6">
        <h3 className="mt-2 mb-0">{title}</h3>
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <hr className="text-lightest mt-6 mb-6" />
          </div>
          <div className="col-span-4">
            <Link to={"/information/wilderness-safety/"}>
              <StaticImage
                src="../../images/sidebar/wilderness.jpg"
                alt="Alpine National Parks"
                placeholder="blurred"
                className="w-24 h-24"
              />
            </Link>
          </div>
          <div className="col-span-8">
            <Link to={"/information/wilderness-safety/"}>
              <LineTagComponent text="Wilderness Safety" />
              <p className="text-base mt-4 text-black opacity-100">
                Read up on how to stay safe in the wilderness.
              </p>
            </Link>
          </div>
          <div className="col-span-12">
            <hr className="text-lightest mt-6 mb-6" />
          </div>
          <div className="col-span-4">
            <Link to={"/information/bear-safety/"}>
              <StaticImage
                src="../../images/sidebar/bear.jpg"
                alt="Alpine National Parks"
                placeholder="blurred"
                className="w-24 h-24"
              />
            </Link>
          </div>
          <div className="col-span-8">
            <Link to={"/information/bear-safety/"}>
              <LineTagComponent text="Bear Safety" />
              <p className="text-base mt-4 text-black opacity-100">
                Read more about bear encounters in the wild.
              </p>
            </Link>
          </div>
          <div className="col-span-12">
            <hr className="text-lightest mt-6 mb-6" />
          </div>
          <div className="col-span-4">
            <Link to={"/information/lightning-information/"}>
              <StaticImage
                src="../../images/sidebar/lightning.jpg"
                alt="Alpine National Parks"
                placeholder="blurred"
                className="w-24 h-24"
              />
            </Link>
          </div>
          <div className="col-span-8">
            <Link to={"/information/lightning-information/"}>
              <LineTagComponent text="Lightning" />
              <p className="text-base mt-4 text-black opacity-100">
                Take precautions when encountering lightning.
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
