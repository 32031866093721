import moment from "moment"
import { RichText } from "prismic-reactjs"
import React from "react"
import Layout from "../components/layout/Layout"
import WideContainer from "../components/layout/WideContainer"
import MetaData from "../components/seo/MetaData"
import ArticleSidebar from "../components/sidebar/ArticleSidebar"
import { ArticleModel } from "../models/ArticleModel"

interface Props {
  pageContext: PageContext
  location: any
}

interface PageContext {
  data: ArticleModel
  tags: string[]
  publicationDate: string
}

const linkResolver = doc => {
  switch (doc.type) {
    case "homepage":
      return "/"
  }
}

const ArticleTemplate: React.FunctionComponent<Props> = ({
  pageContext,
  location,
}) => {
  return (
    <Layout>
      <MetaData
        title={`${pageContext.data.article_title[0].text}`}
        description={`${pageContext.data.meta_description[0].text}`}
        thumbnailUrl={pageContext.data.main_image.url}
        imageAlt={`${pageContext.data.main_image.alt}`}
        pageUrl={location.pathname}
      />
      <WideContainer center={false}>
        <div className={`container`}>
          <p className="mb-0 mt-6">
            {moment(pageContext.publicationDate).format("MMMM Do YYYY")}
          </p>
          <h1 className="mt-0 mb-4">
            {pageContext.data.article_title[0].text}
          </h1>
        </div>
      </WideContainer>
      <WideContainer center={false} className="mb-2 article">
        <div className={`grid grid-cols-12`}>
          <div className="col-span-12 md:col-span-8">
            <img
              src={pageContext.data.main_image.url}
              alt={pageContext.data.main_image.alt}
              loading="eager"
            />
            {RichText.render(pageContext.data.article_text)}
          </div>
          <ArticleSidebar title={"Prepare for your trip"} />
        </div>
      </WideContainer>
    </Layout>
  )
}

export default ArticleTemplate
