import React from "react"
import Variables from "../../constants/Variables"

interface Props {
  text: string
}

const LineTagComponent: React.FunctionComponent<Props> = ({ text }) => {
  return (
    <span
      className="border-b-2 border-light text-theme text-sm uppercase leading-7 pb-1"
      style={{ fontFamily: Variables.fontFamilyHeadline }}
    >
      {text}
    </span>
  )
}

export default LineTagComponent
